var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.renderPage)?_c('b-row',[_c('b-container',[_c('b-overlay',{attrs:{"show":_vm.showLoading,"opacity":1,"variant":"light","rounded":"sm"},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('div',{staticClass:"d-flex align-items-center"},[_c('strong',[_vm._v("Enviando Arquivo, Aguarde...")]),_c('b-icon',{attrs:{"icon":"stopwatch","font-scale":"3","animation":"cylon"}}),_c('b-spinner',{attrs:{"small":"","type":"grow","variant":"primary"}}),_c('b-spinner',{attrs:{"type":"grow","variant":"primary"}}),_c('b-spinner',{attrs:{"small":"","type":"grow","variant":"primary"}})],1)]},proxy:true}],null,false,744423006)},[_c('b-card',{attrs:{"title":"Importação Arquivos Rede"}},[_c('validation-observer',{ref:"uploadRede",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',[_c('b-form-group',{attrs:{"label":"Tipo do Arquivo","label-for":"v-file"}},[_c('validation-provider',{attrs:{"name":"Upload","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","options":_vm.redeFileOptions,"placeholder":"Selecione o Tipo do Arquivo"},model:{value:(_vm.selectedFileOption),callback:function ($$v) {_vm.selectedFileOption=$$v},expression:"selectedFileOption"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Upload","rules":("required|" + (_vm.selectedFileOption.title === "Clientes" ? "fileUploadClient"
                  : _vm.selectedFileOption.title === "Bancário" ? "fileUploadBank"
                    : ""))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-file',{attrs:{"placeholder":"Arraste ou selecione um arquivo","drop-placeholder":"Arraste o arquivo aqui.","disabled":_vm.isDisabledFile},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-button',{staticClass:"btn-upload",attrs:{"variant":"primary","disabled":invalid},on:{"click":_vm.upload}},[_vm._v(" Enviar Arquivo ")])],1)]}}],null,false,3994043095)})],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }