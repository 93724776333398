import axios from 'axios'

const BASE_URL = process.env.VUE_APP_BASE_URL

const token = sessionStorage.getItem('token')
axios.defaults.headers.common.Authorization = `Bearer ${token}`

const TIME_IN_MINUTE = 10 * 60 * 1000 // 10 minutes

async function uploadIndicator(file) {
  try {
    const formData = new FormData()
    formData.append('indicators', file)
    const { status } = await axios.post(`${BASE_URL}/upload/indicators`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      timeout: TIME_IN_MINUTE,
    })
    return status
  } catch ({ response }) {
    return response.status
  }
}

async function uploadClients(file) {
  try {
    const formData = new FormData()
    formData.append('clients', file)
    const { status } = await axios.post(`${BASE_URL}/upload/clients`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      timeout: TIME_IN_MINUTE,
    })
    return status
  } catch ({ response }) {
    return response.status
  }
}

async function uploadBanking(file) {
  try {
    const formData = new FormData()
    formData.append('banking', file)
    const { status } = await axios.post(`${BASE_URL}/upload/banking`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      timeout: TIME_IN_MINUTE,
    })
    return status
  } catch ({ response }) {
    return response.status
  }
}

export {
  uploadIndicator,
  uploadClients,
  uploadBanking,
}
